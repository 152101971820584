import React from 'react';

export default function Footer() {
  let year = new Date().getFullYear()
  return (
    <footer className="bg-black small text-center text-white-50">
      <div className="container">
        Copyright &copy; Paula Amorós Florit {year}
      </div>
    </footer>
  );
}
